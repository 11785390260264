import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Té forma de copa més o menys tancada que després es va obrint per acabar plana, que mesura uns 3 cm de diàmetre. El marge està voltat cap a l’interior i pot ésser dentat. L’himeni és de color castany clar en contrast amb la superficie externa que és de color blanc pur i un poc granulosa. Les espores són el·líptiques, llises, de 17,5-20 x 10-11 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      